import Header from 'components/Header';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import {
  GetQuestions,
  PostParticipant,
  CheckParticipantSlug,
} from "utils/api";
import { toast } from "react-toastify";


export default function Start() {

  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();


  const doLogin = async (data) => {
    // Check Participant Slug If Already Exists
    const slug = slugify(
      data.participant_name + "-" + data.participant_family_name,
      {
        lower: true,
      }
    );
    const checkParticipantSlug = await CheckParticipantSlug(slug);

    if (
      !checkParticipantSlug?.data?.data?.length ||
      checkParticipantSlug?.data?.data?.length === 0
    ) {

      const currentDate = new Date();

      // POST New participant
      const participants = await PostParticipant({
        name: data.participant_name,
        family_name: data.participant_family_name,
        zone: data.participant_zone,
        slug: slug,
        start_time: currentDate,
        score: 0,
      });

      if (participants) {
        // Save the new user in localStorage
        localStorage?.setItem(
          "user",
          JSON.stringify({
            ...participants?.data?.data?.attributes,
            id: participants?.data?.data.id,
          })
        );
        // GET Target QUIZZ
        const questions = await GetQuestions();

        if( questions) {

          const questions_array = questions?.data?.data.map((item) => item.attributes);

          // Save the target quizz in localStorage
          localStorage?.setItem(
            "questions",
            JSON.stringify(questions_array)
          );
          navigate("/start");
        } else {
          toast.error("Problème pour récupérer les données des questions.");
        }
      }
    } else {
      toast.error("Cet utilisateur existe déjà.");
    }
  };

  return (
    <>
      <Header
        showLogo={true}
        objectif="Objectif sécurité"
        title="Quiz règles de circulation"
      />
      <main className="layout layout--gray">
        <div className="layout__wrapper">

          <div className="layout__center-path">
            <div className="layout__form">

              <form className="form" onSubmit={handleSubmit(doLogin)}>

                <input {...register("participant_name", { required: true})} className="form__input-text" placeholder="Prénom"/>
                <input {...register("participant_family_name", { required: true})} className="form__input-text" placeholder="Nom"/>
                <select {...register("participant_zone", { required: true})} id="" className="form__select">
                  <option value="">Selectionnez une zone</option>
                  <option value="Macon">Macon</option>
                  <option value="Paris">Paris</option>
                  <option value="FDV GMS">FDV GMS</option>
                  <option value="FDV CHD">FDV CHD</option>
                </select>

                <div className="form__btn">
                  <button className="btn btn--orange">Suivant</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="layout__color-band"></div>
      </main>
    </>
  );
}
