'use client'

import { useEffect, useState } from "react";

export default function Header(props) {
  const [time, setTime] = useState("00:00:00");
  let classes = props.withBg? "header header--with-bg":"header";
  classes = props.extraClasses? classes + " " + props.extraClasses : classes;


  const showTimer = (ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60))
      .toString()
      .padStart(2, "0");
    setTime(
      minute + ":" + second
    );
  };

  useEffect(() => {

    if (!props.startTime) {
      return;
    }

    var id = setInterval(() => {
      var left = (new Date() - new Date(props.startTime));
      showTimer(left);
      if (left <= 0) {
        setTime("00:00:00:00");
        clearInterval(id);
      }
    }, 1);
    return () => clearInterval(id);
  }, [props.startTime]);


  return (
      <header className={classes}>
          {props.showLogo ?
            <img
              src="/eckes-granini-logo.svg"
              className="header__logo"
              alt=""
            />
          : ""
          }

          {props.objectif ?
            <div className="header__objectif">Objectif sécurité</div>
          : ""
          }
          {props.title ?
            <div className="header__title">Quiz règles de circulation</div>
          : ""
          }

          <div className="header__right">
            {props.currentQuestion ?
              <div className="header__question-count">
                <div className="question-count">
                  <div className="question-count__top">
                    Question
                  </div>
                  <div className="question-count__bot">
                    {props.currentQuestion}/{props.totalQuestions}
                  </div>
                </div>
              </div>
            : ""
            }
            {props.startTime ?
              <div className="header__clock">
                <div className="clock">
                  <img
                    src="/clock.svg"
                    className="clock__img"
                    alt=""
                  />
                  <div className="clock__timer">
                    {time}
                  </div>
                </div>
              </div>
            : ""
            }

          </div>
      </header>
  );
}
