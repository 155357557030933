import Header from 'components/Header';
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  const goToIntro = () => {
    navigate("/intro")
  }

  return (
    <>
      <Header objectif="Objectif sécurité"/>

      <main className="layout layout--orange layout--home">
        <h1 className="layout__main-title">Quiz règles de circulation</h1>
        <p className="layout__main-intro">
          Parce qu’on doit aussi prendre soin de soi et soin des autres sur la route,<br />
          il est important de se remémorer quelques règles.<br />
          Sois bien attentif.ve, certaines questions sont à choix multiples !<br />
          Le/la meilleur.e (nombre de points + temps) remportera un lot.<br />
          Bonne chance !
        </p>

        <div className="layout__btn">
          <button onClick={()=>{goToIntro()}} className="btn">Commencer</button>
        </div>

        <div className="layout__bottom-img">
          <img
            src="/main-bot.jpg"
            className="bottom-img"
            alt=""
          />
        </div>

        <img
          src="/eckes-granini-logo.svg"
          className="layout__logo"
          alt=""
        />
      </main>
    </>
  );
}
