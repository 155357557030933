import './App.scss';
import Router from "./router";
import GameContextProvider from "./contexts/GameContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FlipMobileMsg from 'components/FlipMobileMsg'

function App() {
  return (
    <>
      <GameContextProvider>
        <ToastContainer/>
        <Router />
        <FlipMobileMsg />
      </GameContextProvider>
    </>
  );
}

export default App;
